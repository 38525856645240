import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroGeneric from "../components/HeroGeneric";
import GuideTiles from "../components/GuideTiles";
import GuidesCards from "../components/GuidesCards";
import UsefulLinks from "../components/UsefulLinks";
import GuidesSection from "../components/GuidesSection";
import transformTheme from "../libs/transformTheme";
import HeroGuidesIndex from "../components/HeroGuidesIndex";
import ResourcesSection from "../components/ResourcesSection";
import FooterCTA from "../components/FooterCTA";
import Accordion from "../components/Accordion";
import FeaturedGuidesBlock from "../components/FeaturedGuidesBlock";

export default function Category({ data, pageContext }) {
	const {
		title,
		heroText,
		metaTitle,
		metaDescription,
		metaShareImage,
		usefulLinkEntries,
		secondaryFeaturedGuide,
		featuredGuides,
		footerBannerTitle,
		footerBannerText,
		footerBannerButton,
		faqsTitle,
		faqsItems,
		categoryTheme: colorTheme,
	} = data?.craft?.category ?? [];

	const entries = data?.craft?.tiles ?? [];
	const resources = data?.craft?.resources ?? [];
	const resourcesCategory = data?.craft?.resourcesCategory ?? [];
	const terms = pageContext?.glossaryTerms?.data?.craft?.entries ?? [];
	const heroNav = pageContext?.heroNav?.data?.craft;
	const { currentUrl } = pageContext;

	const { lightTheme, darkTheme } = transformTheme(colorTheme);
	return (
		<Layout className="layout--category" colorTheme={darkTheme}>
			<SEO
				title={metaTitle}
				description={metaDescription}
				image={metaShareImage}
			/>

			<HeroGuidesIndex
				title={title}
				text={heroText}
				colorTheme={colorTheme}
				navLinks={heroNav}
				currentUrl={currentUrl}
			/>

			<FeaturedGuidesBlock colorTheme={darkTheme} blocks={featuredGuides} />

			<GuidesSection
				entries={entries}
				featuredGuide={secondaryFeaturedGuide}
				terms={terms}
				topBorder={false}
				maxItems={9}
				showMore
			/>

			<ResourcesSection
				entries={resources}
				title={resourcesCategory.title}
				category={[resourcesCategory]}
				colorTheme={lightTheme}
				prefixCta
			/>

			<UsefulLinks colorTheme={lightTheme} entries={usefulLinkEntries} />

			<Accordion title={faqsTitle} items={faqsItems} colorTheme={darkTheme} />

			<FooterCTA
				colorTheme={darkTheme}
				title={footerBannerTitle}
				text={footerBannerText}
				cta={footerBannerButton}
			/>

			{/* <GuideTiles items={tiles} /> */}
		</Layout>
	);
}

export const pageQuery = graphql`
	fragment CategoryUsefulLinkExcerpt on CraftCMS_guidesInsights_guides_Entry {
		uri
		url
		slug
		title
		heroImage {
			title
			filename
			width
			height
			url
			imagerX(transform: "maxWidth-1920") {
				width
				height
				url
			}
			imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
				width
				height
				url
			}
		}
		guideCategory {
			id
			title
			... on CraftCMS_guidesAndInsightsCategories_Category {
				id
				categoryTheme
				categorySecondaryTitle
				uri
			}
		}
	}

	query categories($id: CraftCMS_QueryArgument!) {
		craft {
			tiles: entries(
				section: "guidesInsights"
				type: "guides"
				relatedToCategories: { id: [$id] }
			) {
				... on CraftCMS_guidesInsights_guides_Entry {
					...CategoryUsefulLinkExcerpt
				}
			}
			resources: entries(
				section: "guidesInsights"
				type: "guides"
				relatedToCategories: [{ id: [$id] }, { title: "resources" }]
			) {
				... on CraftCMS_guidesInsights_guides_Entry {
					...CategoryUsefulLinkExcerpt
				}
			}
			resourcesCategory: category(title: "resources") {
				id
				title
				... on CraftCMS_guidesAndInsightsCategories_Category {
					uri
				}
			}
			category(id: [$id]) {
				id
				... on CraftCMS_guidesAndInsightsCategories_Category {
					title
					categoryTheme
					usefulLinkEntries {
						... on CraftCMS_guidesInsights_guides_Entry {
							...CategoryUsefulLinkExcerpt
						}
					}
					featuredGuides {
						... on CraftCMS_guidesInsights_guides_Entry {
							...FeaturedGuideExcerpt
						}
					}
					secondaryFeaturedGuide {
						... on CraftCMS_guidesInsights_guides_Entry {
							...FeaturedGuideExcerpt
						}
					}
					faqsTitle
					faqsItems {
						... on CraftCMS_faqsItems_faqsItem_BlockType {
							id
							faqsItemTitle
							faqsItemText
						}
					}
					footerBannerTitle
					footerBannerText
					footerBannerButton {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
				}
			}
		}
	}
`;
